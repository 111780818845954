:root {
  --primary: "#0e59da"; 
}

* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  text-shadow: 1px 1px 1px rgba(0,0,0,0.004);
}

@font-face {
  font-family: "montserrat";
  src: url("/public/fonts/montserrat-variablefont_wght-webfont.woff2")
      format("woff2"),
    url("/public/fonts/montserrat-variablefont_wght-webfont.woff")
      format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "montserrat_italic";
  src: url("/public/fonts/montserrat-italic-variablefont_wght-webfont.woff2")
      format("woff2"),
    url("/public/fonts/montserrat-italic-variablefont_wght-webfont.woff")
      format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "montserratbold_italic";
  src: url("/public/fonts/montserrat-bolditalic-webfont.woff2") format("woff2"),
    url("/public/fonts/montserrat-bolditalic-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "montserrat_medium";
  src: url("/public/fonts/montserrat-medium-webfont.woff2") format("woff2"),
    url("/public/fonts/montserrat-medium-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}


@font-face {
  font-family: 'montserrat_regular';
  src: url('/public/fonts/montserrat-regular-webfont.woff2') format('woff2'),
       url('/public/fonts/montserrat-regular-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'montserrat_bold';
  src: url('/public/fonts/montserrat-bold-webfont.woff2') format('woff2'),
       url('/public/fonts/montserrat-bold-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}


@font-face {
  font-family: 'montserrat_semibold';
  src: url('/public/fonts/montserrat-semibold-webfont.woff2') format('woff2'),
       url('/public/fonts/montserrat-semibold-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.toast-container {
  min-width: 456px;
  min-height: 67px;
  color: #fff;
  text-align: center;
  border-radius: 5px;
  padding: 16px;
  bottom: -2rem;
  right: 9.7rem;
  margin-top: -20px;
  position: relative;
  font-family: 'montserrat_regular' !important;
}

.toast-container > div {
  font-family: 'montserrat_regular' !important;
  font-size: 14px;
  text-align: start;
  line-height: 120%;
}

.Toastify__toast-body {
  display: flex;
  align-items: flex-start !important;
}

.Toastify__toast-icon {
  margin-inline-start: 15px;
}