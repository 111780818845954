:root {
  --primary: "#0e59da"; 
  --secondary: #f65f1f;
  --primary-light: #e6eeff;
  --background: #ffffff;
  --background-primary: #ffffff;
  --text-primary: var(--primary);
  --background-primary-light: #e6eeff;
  --icon-hover-primary: #ffffff;
  --icon-white: #ffffff;
  --border-white: #ffffff;
  --text-white: #ffffff;

  --loader-white: #ffffff;
  --text-white: #ffffff;

    // Font family
  --font-montserrat-300: "montserrat-light", "avenir", -apple-system,
  BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell",
  "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;

  --font-montserrat-400: "montserrat-regular", "avenir", -apple-system,
    BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell",
    "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;

  --font-montserrat-500: "montserrat-medium", "avenir", -apple-system,
    BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell",
    "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;

  --font-montserrat-700: "montserrat-bold", "avenir", -apple-system,
    BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell",
    "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
    
}
